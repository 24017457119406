
import Config from 'utils/Config'
import OAuthApiStoreSync from 'utils/OAuthApiStoreSync'
import OAuthStoreSync from 'utils/OAuthStoreSync'

import OAuthKitWeb from '@vayapin/oauth-kit-web'
import CookieStorage from '@vayapin/oauth-kit/storage/CookieStorage'
import LocalStorage from '@vayapin/oauth-kit/storage/LocalStorage'

const cookieSecure = Config.COOKIE_SECURE

function configureOAuthKit() {
  OAuthKitWeb.configure({
    oauthKitConfig: {
      clientId: Config.ID_OAUTH_ID,
      clientScope: 'vayapin_admin',
      idHost: Config.ID_URL,
      idApiHost: Config.ID_API_URL,
      redirectUri: `${Config.ADMIN_URL.replace(/\/$/, '')}/oauth-callback`,
      authStorage: new CookieStorage({
        prefix: 'vp-admin',
        cookieSecure,
        sameSite: (key) => key === 'token' ? undefined : 'Strict',
        expires: (key) => {
          switch (key) {
            case 'token':
              return 1/48 // (days) 30 minutes
            case 'refreshToken':
              return 1/48 // (days) 30 minutes
            case 'authData':
              return 2
            case 'userData':
              return 2
            default:
              return undefined
          }
        }
      }),
      userInfoStorage: new LocalStorage({ prefix: 'vp-admin' }),
    },
    // oauthKitReactConfig: {
    //   debug: true,
    // },
    autoRefreshToken: true
  })

  void OAuthApiStoreSync.configure()
  void OAuthStoreSync.configure()
}

export default configureOAuthKit
