import { ConfigProvider } from 'antd'
import HealthCheck from 'components/HealthCheck'
import StagingNote from 'components/StagingNote'
import { FC, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { RouterContextProvider } from 'services/Router'
import { configureOAuthKit } from 'setup'
import Store from 'Store'
import AppActionCreators from 'Store/app/actionCreators'
import Config from 'utils/Config'
import CoreServiceApollo from 'utils/CoreServiceApollo'

import { ApolloProvider } from '@apollo/client'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Provider as AuthProvider } from '@vayapin/oauth-kit-web'

import MainLayout from '../MainLayout'

// ---------------------------------------
// Load configuration
// ---------------------------------------
Store.get()

configureOAuthKit()

const queryClient = new QueryClient()

// ---------------------------------------
// App
// ---------------------------------------
const RootLayout: FC<unknown> = () => {
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    Store.get().dispatch(
      AppActionCreators.appConfigFullSet(Config)
    )
  }, [])

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const apolloClient = CoreServiceApollo.get().getClient()

  return (
    <ConfigProvider theme={{}}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <HealthCheck>
          <RouterContextProvider>
            <AuthProvider authBarrier signedOutModal authBarrierRedirectIfSignedOut>
              <QueryClientProvider client={queryClient}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <ApolloProvider client={apolloClient}>
                  {/* @ts-ignore */}
                  <Provider store={Store.get()}>
                    {Config.STAGING === true && (
                      <StagingNote position="topLeft" />
                    )}
                    <MainLayout>
                      <Outlet />
                    </MainLayout>
                    {Config.STAGING === true && (
                      <StagingNote position="bottomRight" />
                    )}
                  </Provider>
                </ApolloProvider>
              </QueryClientProvider>
            </AuthProvider>
          </RouterContextProvider>
        </HealthCheck>
      </LocalizationProvider>
    </ConfigProvider>
  )
}

export default RootLayout
